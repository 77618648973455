<template>
  <div class="language" @click="changeLocale">
    <div :title="language[selectedIndex]" class="flag-icon" :class="$i18n.flag ? `flag-icon-${$i18n.flag}` : 'flag-icon-us'"/>
    <!-- <flag :squared="false" :iso="language[selectedIndex]"/> -->
  </div>
</template>

<script>
export default {
  name: 'AdminLanguage',
  data() {
    return {
      language: ['en', 'it', 'es'],
      selectedIndex: 0,
    }
  },
  methods: {
    changeLocale() {
      if (this.selectedIndex < this.language.length - 1) {
        this.selectedIndex ++
      } else {
        this.selectedIndex = 0
      }

      this.$i18n.locale = this.language[this.selectedIndex]
      this.$i18n.flag = this.$i18n.locale === 'en' ? 'us' : this.language[this.selectedIndex]
    }
  }
}
</script>

<style lang="scss" scoped>
.language {
  cursor: pointer;
}
</style>