<template>
  <div class="admin-menu">
    <div class="menu-items sticky-top">
      <router-link to="/">
        <img class="logo" src="@/assets/images/logo.png" />
      </router-link>
      <template v-if="loggedIn">
        <div class="menu-item user">
          <div class="logged-admin">
            <FontAwesomeIcon :icon="['fas', 'user']" class="mr-1"/> {{user.firstname}}
          </div>
          <AdminLanguage />
        </div>
        <div class="menu-item" :class="{ 'exploded': selectedSection === index }" v-for="(section, index) in sections" :key="`section-${index}`">
          <template v-if="section.to">
            <router-link class="item" :to="{ path: section.to }">{{$t(section.label)}}</router-link>
          </template>
          <template v-else>
            <div class="item cursor-pointer" :class="{ 'has-children': section.children }" @click="selectSection(section,index)">
              {{$t(section.label)}}
              <FontAwesomeIcon class="ml-3" :icon="['fas', 'angle-down']" v-if="section.children" />
            </div>
            <div class="sub-menu-item" v-for="(child, index) in section.children" :key="`child-${index}`">
              <FontAwesomeIcon class="mr-1" :icon="['fas', 'angle-right']"/>
              <router-link class="sub-item-link" :to="{ path: child.to, query: { type: child.type} }">
                {{$t(child.label)}}
              </router-link>
            </div>
          </template>
        </div>
        <div class="menu-item logout cursor-pointer" @click="logout">{{$t('Logout')}}</div>
      </template>
      <template v-else>
        <div class="login">
          <router-link class="menu-item" :to="{ name: 'admin-login' }">{{$t('Login')}}</router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import adminSections from '@/json/admin-sections'
import { mapState } from 'vuex'
import { authService } from '@/services'
import AdminLanguage from '@/components/admin/AdminLanguage'

export default {
  name: 'MenuAdmin',
  components: {
    AdminLanguage,
  },
  data() {
    return {
      selectedSection: null,
      sections: adminSections,
    }
  },
  computed: {
    ...mapState({
      user: state => state.userStore.user,
      loggedIn: state => state.userStore.loggedIn,
      userFilterData: state => state.adminUserStore.filterData,
      reservationFilterData: state => state.adminReservationStore.filterData,
      paymentsFilterData: state => state.adminPaymentStore.paymentsFilterData,
      bankTransferFilterData: state => state.adminPaymentStore.bankTransferFilterData
    }),
  },
  methods: {
    selectSection(section,index) {
      if (this.selectedSection === index) {
        this.selectedSection = null
      } else {
        this.selectedSection = index
      }
      //Clear filterData of the corresponding sections
      this.clearFilterData(section.label)
    },
    logout() {
      authService.logout()
    },
    clearFilterData(sectionLabel){
      //Users
      if(sectionLabel==='Users' && this.userFilterData!=null){
        this.$store.commit('adminUserStore/setFilterData', null)
      }
      //Reservations
      if(sectionLabel==='Reservations' && this.reservationFilterData!=null){
        this.$store.commit('adminReservationStore/setFilterData', null)
      }
      //Payments
      if(sectionLabel === 'Payments'){
        if(this.paymentsFilterData!=null){
          this.$store.commit('adminPaymentStore/setPaymentsFilterData', null)
        }
        if(this.bankTransferFilterData!=null){
          this.$store.commit('adminPaymentStore/setBankTransferFilterData', null)
        }
      }
    }
  },
  watch: {
    $route: {
      handler() {
        this.selectedSection = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-menu {
  background-color: $dark-blue;
  // padding: 20px 30px;

  .menu-items {
    width: 250px;

    &.sticky-top {
      top: 20px;
      z-index: 10;
    }
    .logo {
      margin-bottom: 30px;
      padding: 0 20px;
    }
    .menu-item {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $white;
      border-top: .5px solid rgba($black, .5);

      &.current {
        background-color: rgba($white, .1);
      }
      &.user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 20px;
        border-top: none;
      }
      &.logout {
        padding: 10px 20px;
      }
      &:hover:not(.user) {
        background-color: rgba($white, .1);
      }
      &:last-child {
        border-bottom: .5px solid rgba($black, .5);
      }
      .item {
        @include font-style($montserrat, 'semibold', $font-14);
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
      }

      &.exploded {
        .sub-menu-item {
          max-height: 100px;
          padding: 0 20px 10px 20px;
        }
      }
      .sub-menu-item {
        @include font-style($montserrat-light, 'light', $font-14);
        max-height: 0;
        overflow-y: hidden;
        transition: all .3s ease-in-out;
        padding: 0 20px;

        .sub-item-link {
          color: $white;
          white-space: nowrap;
        }
      }
    }
  }
  .login {
    width: 250px;

    .menu-item {
      padding: 10px 20px;
      width: 100%;
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .admin-menu {
    border-right: 1px solid $disabled;

    &:last-child {
      border: none;
    }
  }
}
</style>
