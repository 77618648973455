<template>
  <div class="admin">
    <MenuAdmin/>
    <div class="admin-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import MenuAdmin from '@/components/admin/MenuAdmin'
import { store } from '@/stores'
import { roles } from '@/enums'
import { isAdmin } from '@/helpers/functions'

export default {
  name: 'AdminPage',
  components: {
    MenuAdmin,
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.userStore.loggedIn) {
      const userRoles = store.state.userStore.user.roles

      if (!isAdmin(userRoles)) {
        next({ name: 'profile' })
      } else {
        store.dispatch('adminAmenityStore/getAmenities')
        store.dispatch('adminHouseRuleStore/getHouseRules')
        store.dispatch('adminCharacteristicStore/getCharacteristics')
        store.dispatch('adminOwnerStore/getOwners')
        store.dispatch('adminBuildingTypeStore/getBuildingTypes')
        store.dispatch('adminServiceTypeStore/getServiceTypes')
        next()
      }
    } else {
      next({ name: 'homepage' })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin {
  min-height: 100vh;
  display: flex;

  .admin-content {
    width: 100%;
    padding: 30px;
  }
}
</style>
